<template>
  <div class="auth-wrapper auth-v1 px-2">
    <div class="auth-inner py-2">

      <!-- Login v1 -->
      <b-card class="mb-0">
        <b-link
          class="brand-logo"
          style="align-items: center;"
        >
          <Logo />

          <h2 class="brand-text text-primary ml-1 mb-0">
            Yenestock
          </h2>
        </b-link>

        <b-card-title class="mb-1">
          Welcome to Yenestock! 👋
        </b-card-title>
        <b-card-text class="mb-2">
          Please sign-in to your account
        </b-card-text>

        <!-- form -->
        <validation-observer
          ref="loginForm"
          #default="{invalid}"
        >
          <b-form
            class="auth-login-form mt-2"
            @submit.prevent
          >

            <b-form-group
              label-for="Company name"
              label="Company name"
            >
              <validation-provider
                #default="{ errors }"
                name="Company name"
                rules="required"
              >
                <b-form-input
                  id="Company name"
                  v-model="companyName"
                  autocomplete="off"
                  readonly
                  onfocus="this.removeAttribute('readonly');"
                  name="login-company-name"
                  :state="errors.length > 0 ? false:null"
                  placeholder=""
                  autofocus
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <!-- password -->
            <b-form-group>
              <!-- <div class="d-flex justify-content-between">
                <label for="password">Password</label>
                <b-link :to="{name:'auth-forgot-password-v1'}">
                  <small>Forgot Password?</small>
                </b-link>
              </div> -->
              <validation-provider
                #default="{ errors }"
                name="Company password"
                rules="required"
              >
                <b-input-group
                  class="input-group-merge"
                  :class="errors.length > 0 ? 'is-invalid':null"
                >
                  <b-form-input
                    id="password"
                    v-model="password"
                    autocomplete="off"
                    readonly
                    onfocus="this.removeAttribute('readonly');"
                    :type="passwordFieldType"
                    class="form-control-merge"
                    :state="errors.length > 0 ? false:null"
                    name="login-password"
                    placeholder=""
                  />

                  <b-input-group-append is-text>
                    <feather-icon
                      class="cursor-pointer"
                      :icon="passwordToggleIcon"
                      @click="togglePasswordVisibility"
                    />
                  </b-input-group-append>
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <!-- submit button -->
            <b-button
              variant="primary"
              type="submit"
              block
              :loading="loading"
              :disabled="loading || invalid"
              @click="handleLogin"
            >
              <span v-show="!loading">Login</span>
              <b-spinner
                v-if="loading"
                small
              />
            </b-button>
          </b-form>
        </validation-observer>

        <b-card-text class="text-center mt-2">
          <span>New on our platform? </span>
          <b-link href="tel:+251911273764">
            <span>Contact us</span>
          </b-link>
        </b-card-text>
        <!--
        <div class="divider my-2">
          <div class="divider-text">
            or
          </div>
        </div> -->

      </b-card>
    </div>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BButton, BForm, BFormInput, BFormGroup, BCard, BLink, BCardTitle, BCardText, BInputGroup, BInputGroupAppend, BSpinner,
} from 'bootstrap-vue'
import Logo from '@core/layouts/components/Logo.vue'
import { required } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { companyModelField } from '@/api/field_model'

export default {
  components: {
    // BSV
    BButton,
    BSpinner,
    BForm,
    BFormInput,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
    BFormGroup,
    BCard,
    BCardTitle,
    BLink,
    Logo,
    BCardText,
    BInputGroup,
    BInputGroupAppend,
    ValidationProvider,
    ValidationObserver,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      companyName: '',
      password: '',
      loading: false,
      status: '',
      // validation rules
      required,
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
  },
  methods: {
    showToast(title, text) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title,
          icon: 'icon-alert-circle',
          text,
          variant: 'danger',
        },
      },
      {
        timeout: 2500,
        position: 'bottom-right',
      })
    },
    handleLogin() {
      this.loading = true
      const payload = {
        [companyModelField.companyDomain]: this.companyName,
        [companyModelField.companyPassword]: this.password,
        notify: this.showToast,
      }
      this.$store.dispatch('auth/loginByCompany', payload)
        .then(() => { this.loading = false })
        .catch(error => {
          this.loading = false
          this.showToast('Error', error.message)
        })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
